<template>
    <v-btn position="fixed" location="bottom right" class="global-fab" icon="mdi-headset" color="blue" name="needHelp"
        @click="needHelpDiag = !needHelpDiag" elevation="8" />

    <v-bottom-sheet v-model="needHelpDiag" width="350" location="center" content-class="v-bottom-sheet">

        <v-list density="compact" nav color="transparent" class="pa-5">
            <h2>Need help?</h2>
            <v-list-item class="nav-link" v-for="(item, index) of contacts" :key="index" link :href="item.link"
                :title="item.title" :prepend-icon="item.icon" :color="item.color" single />
        </v-list>

    </v-bottom-sheet>
</template>
<script setup>
const needHelpDiag = ref(false);

const contacts = ref([
    {
        title: 'Send us an email',
        link: 'mailto:admin@diskubrepalawan.com',
        icon: 'mdi-gmail',
        color: '#c71610',
    },
    {
        title: 'Call us',
        link: 'tel:+639632160905',
        icon: 'mdi-phone',
        color: '#c71610',
    },
    {
        title: 'Send us SMS',
        link: 'sms://+639632160905',
        icon: 'mdi-message',
        color: '#c71610',
    },
    {
        title: 'Contact us on WhatsApp',
        link: 'https://api.whatsapp.com/send?phone=639632160905',
        icon: 'mdi-whatsapp',
        color: '#25d366',
    },
    {
        title: 'Contact us on Facebook',
        link: 'https://m.me/diskubretravel',
        icon: 'mdi-facebook-messenger',
        color: '#168AFF',
    },
]);
const colors = ['indigo', 'success', 'warning', 'error', 'info', 'teal', 'primary', 'green', 'orange', 'blue'];
</script>