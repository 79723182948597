import { createVuetify } from 'vuetify';
import { fa } from 'vuetify/iconsets/fa4';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import 'vuetify/styles';
export default defineNuxtPlugin((nuxtApp) => {
	const vuetify = createVuetify({
		ssr: true,
		display: {
			mobileBreakpoint: 'sm',
			thresholds: {
				xs: 0,
				sm: 340,
				md: 540,
				lg: 800,
				xl: 1280,
			}
		},
		theme: false,
		icons: {
			defaultSet: 'mdi',
			aliases,
			sets: {
				fa,
				mdi,
			},
		},
	});
	nuxtApp.vueApp.use(vuetify);
});
