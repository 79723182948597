export const slugify = (text: String): String => {
	return text
		.toString() // Convert to a string
		.toLowerCase() // Convert the string to lowercase letters
		.normalize('NFD') // The normalize('NFD') method will replace accented characters with their decomposed form
		.replace(/\p{Diacritic}/gu, '') // Remove accent marks
		.trim() // Remove whitespace from both sides of a string
		.replace(/\s+/g, '-') // Replace spaces with dashes
		.replace(/[^\w\-]+/g, '') // Remove all non-word chars (everything except numbers and letters)
		.replace(/\-\-+/g, '-'); // Replace multiple dashes with single dash
};

export const formatCurrency = (source: number, currency: string = 'USD', locale: string = 'en-US') => {
	let value = parseFloat(parseFloat(source.toString()).toFixed(2));
	if (!value) return;
	if (typeof value !== 'number') {
		return value;
	}
	if (!value || !currency || !locale) return;
	if (value == 0) return 0;
	const formatter = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: currency,
		currencyDisplay: 'code',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});

	return formatter.format(value);
};

export const makeRef = () => {
	let result = '';
	let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	let charactersLength = characters.length;
	for (let i = 0; i < 6; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const shaker = (id: string) => {
	window.scrollTo(0, 0);
	const elem = document.getElementById(id);;
	if (elem) {
		document.getElementById(id).scrollIntoView();
		document.getElementById(id).classList.add('shake');
		setTimeout(() => {
			document.getElementById(id).classList.remove('shake');
		}, 2000);
	}

};

export const isMobileUser = () => {
	const userAgent = navigator.userAgent;
	// Regular expressions for mobile User Agent detection
	const mobileRegex = /iphone|ipod|ipad|android|blackberry|opera mini|opera mobi|iemobile|windows phone|kindle|mobile|silk|webos|fennec/i;

	return mobileRegex.test(userAgent);
};
export const formatAMPM = (timeString: string): string => {
	if (timeString.includes('AM') || timeString.includes('PM')) return timeString;
	const [hours24, minutes] = timeString.split(':').map(Number);
	const hours12 = hours24 % 12 || 12;
	const ampm = hours24 >= 12 ? 'PM' : 'AM';
	const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
	return `${hours12}:${formattedMinutes} ${ampm}`;
};

export const formatDate = (inputDate: string): string => {
	const date = new Date(inputDate);
	if (isNaN(date.getTime())) {
		return 'Invalid Date';
	}
	return date.toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	});
};
export const formatTime = (inputDate: string): string => {
	const date = new Date(inputDate);
	if (isNaN(date.getTime())) {
		return 'Invalid Date';
	}
	return new Intl.DateTimeFormat('en-US', {
		timeStyle: 'short',
	}).format(date);
};

export const loader = (id: string) => {
	const element = document.getElementById(id);
	if (element.classList.contains('loading')) {
		element.classList.remove('loading');
	} else {
		element.classList.add('loading');
	}
};

export const rand = (min: number, max: number) => {
	return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getCurrentTimeAMPM = (): string => {
	const now = new Date();
	const hours = now.getHours();
	const minutes = now.getMinutes();
	const ampm = hours >= 12 ? 'pm' : 'am';

	// Convert hours to 12-hour format
	const formattedHours = hours % 12 || 12;

	// Add leading zero to minutes if needed
	const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

	const currentTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
	return currentTime;
};

export const getCurrentTime24Hour = (): string => {
	const now = new Date();
	const hours = now.getHours();
	const minutes = now.getMinutes();

	// Add leading zero to hours and minutes if needed
	const formattedHours = hours < 10 ? `0${hours}` : hours;
	const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

	const currentTime24Hour = `${formattedHours}:${formattedMinutes}`;
	return currentTime24Hour;
};

export const findKeysWithNullOrBlankValues = (obj: object, parentKey = '') => {
	const keysWithNullOrBlankValues = [];

	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const value = obj[key];
			const currentKey = parentKey ? `${parentKey}.${key}` : key;

			if (value === null || value === '') {
				keysWithNullOrBlankValues.push(currentKey);
			} else if (typeof value === 'object') {
				const nestedKeys = findKeysWithNullOrBlankValues(value, currentKey);
				keysWithNullOrBlankValues.push(...nestedKeys);
			}
		}
	}

	return keysWithNullOrBlankValues;
};

export const removeEmptyObjects = (obj: any) => {
	if (typeof obj !== 'object' || Array.isArray(obj)) {
		return obj;
	}

	return Object.keys(obj).reduce((acc, key) => {
		const value = obj[key];
		if (typeof value === 'object' && !Array.isArray(value)) {
			const filteredValue = removeEmptyObjects(value);
			if (Object.keys(filteredValue).length !== 0) {
				acc[key] = filteredValue;
			}
		} else if (Array.isArray(value)) {
			const filteredArray = value.filter((item) => (typeof item === 'object' ? Object.keys(item).length !== 0 : true));
			if (filteredArray.length !== 0) {
				acc[key] = filteredArray.map((item) => removeEmptyObjects(item));
			}
		} else if (value !== null && value !== undefined) {
			acc[key] = value;
		}
		return acc;
	}, {});
};

export const createPagesFromArray_ = <T>(array: T[]): T[][] => {
	const lastIndex = array.length - 1;
	return array.reduce<T[][]>((acc, curr, index) => {
		let pageNumber: number;
		if (index <= 2) {
			pageNumber = 1;
		} else {
			if (acc[pageNumber - 1] && acc[pageNumber - 1].length > 6) pageNumber = pageNumber + 1;
		}

		if (!acc[pageNumber - 1]) {
			acc[pageNumber - 1] = [];
		}
		if (index === lastIndex) {
			curr = { ...curr, last: true };
		}

		acc[pageNumber - 1].push(curr);

		return acc;
	}, []);
};

export const createPagesFromArray = <T>(arr: T[]): T[][] => {
	return arr.reduce((subarrays, current, index) => {
		if (index === 0) {
			subarrays.push([current]);
		} else if (index < 3) {
			subarrays[0].push(current);
		} else {
			let subarrayIndex = Math.floor((index - 3) / 6) + 1;
			if (!subarrays[subarrayIndex]) {
				subarrays[subarrayIndex] = [];
			}
			subarrays[subarrayIndex].push(current);
		}
		return subarrays;
	}, [] as T[][]);
};

export const nationalities = [
	'Afghan',
	'Albanian',
	'Algerian',
	'American',
	'Andorran',
	'Angolan',
	'Antiguans',
	'Argentinean',
	'Armenian',
	'Australian',
	'Austrian',
	'Azerbaijani',
	'Bahamian',
	'Bahraini',
	'Bangladeshi',
	'Barbadian',
	'Barbudans',
	'Batswana',
	'Belarusian',
	'Belgian',
	'Belizean',
	'Beninese',
	'Bhutanese',
	'Bolivian',
	'Bosnian',
	'Brazilian',
	'British',
	'Bruneian',
	'Bulgarian',
	'Burkinabe',
	'Burmese',
	'Burundian',
	'Cambodian',
	'Cameroonian',
	'Canadian',
	'Cape Verdean',
	'Central African',
	'Chadian',
	'Chilean',
	'Chinese',
	'Colombian',
	'Comoran',
	'Congolese',
	'Costa Rican',
	'Croatian',
	'Cuban',
	'Cypriot',
	'Czech',
	'Danish',
	'Djibouti',
	'Dominican',
	'Dutch',
	'East Timorese',
	'Ecuadorean',
	'Egyptian',
	'Emirian',
	'Equatorial Guinean',
	'Eritrean',
	'Estonian',
	'Ethiopian',
	'Fijian',
	'Filipino',
	'Finnish',
	'French',
	'Gabonese',
	'Gambian',
	'Georgian',
	'German',
	'Ghanaian',
	'Greek',
	'Grenadian',
	'Guatemalan',
	'Guinea-Bissauan',
	'Guinean',
	'Guyanese',
	'Haitian',
	'Herzegovinian',
	'Honduran',
	'Hungarian',
	'I-Kiribati',
	'Icelander',
	'Indian',
	'Indonesian',
	'Iranian',
	'Iraqi',
	'Irish',
	'Israeli',
	'Italian',
	'Ivorian',
	'Jamaican',
	'Japanese',
	'Jordanian',
	'Kazakhstani',
	'Kenyan',
	'Kittian and Nevisian',
	'Kuwaiti',
	'Kyrgyz',
	'Laotian',
	'Latvian',
	'Lebanese',
	'Liberian',
	'Libyan',
	'Liechtensteiner',
	'Lithuanian',
	'Luxembourger',
	'Macedonian',
	'Malagasy',
	'Malawian',
	'Malaysian',
	'Maldivan',
	'Malian',
	'Maltese',
	'Marshallese',
	'Mauritanian',
	'Mauritian',
	'Mexican',
	'Micronesian',
	'Moldovan',
	'Monacan',
	'Mongolian',
	'Moroccan',
	'Mosotho',
	'Motswana',
	'Mozambican',
	'Namibian',
	'Nauruan',
	'Nepalese',
	'New Zealander',
	'Nicaraguan',
	'Nigerian',
	'Nigerien',
	'North Korean',
	'Northern Irish',
	'Norwegian',
	'Omani',
	'Pakistani',
	'Palauan',
	'Panamanian',
	'Papua New Guinean',
	'Paraguayan',
	'Peruvian',
	'Polish',
	'Portuguese',
	'Qatari',
	'Romanian',
	'Russian',
	'Rwandan',
	'Saint Lucian',
	'Salvadoran',
	'Samoan',
	'San Marinese',
	'Sao Tomean',
	'Saudi',
	'Scottish',
	'Senegalese',
	'Serbian',
	'Seychellois',
	'Sierra Leonean',
	'Singaporean',
	'Slovakian',
	'Slovenian',
	'Solomon Islander',
	'Somali',
	'South African',
	'South Korean',
	'Spanish',
	'Sri Lankan',
	'Sudanese',
	'Surinamer',
	'Swazi',
	'Swedish',
	'Swiss',
	'Syrian',
	'Taiwanese',
	'Tajik',
	'Tanzanian',
	'Thai',
	'Togolese',
	'Tongan',
	'Trinidadian or Tobagonian',
	'Tunisian',
	'Turkish',
	'Tuvaluan',
	'Ugandan',
	'Ukrainian',
	'Uruguayan',
	'Uzbekistani',
	'Venezuelan',
	'Vietnamese',
	'Welsh',
	'Yemenite',
	'Zambian',
	'Zimbabwean',
];

export const getSimilarityPercentage = (str1: String, str2: String) => {
	const getLevenshteinDistance = (a: String, b: String) => {
		const matrix = [];

		for (let i = 0; i <= b.length; i++) {
			matrix[i] = [i];
		}

		for (let j = 0; j <= a.length; j++) {
			matrix[0][j] = j;
		}

		for (let i = 1; i <= b.length; i++) {
			for (let j = 1; j <= a.length; j++) {
				if (b.charAt(i - 1) === a.charAt(j - 1)) {
					matrix[i][j] = matrix[i - 1][j - 1];
				} else {
					matrix[i][j] = Math.min(
						matrix[i - 1][j - 1] + 1, // substitution
						matrix[i][j - 1] + 1, // insertion
						matrix[i - 1][j] + 1 // deletion
					);
				}
			}
		}

		return matrix[b.length][a.length];
	};

	const maxLen = Math.max(str1.length, str2.length);
	if (maxLen === 0) {
		return 100; // Both strings are empty
	}
	const distance = getLevenshteinDistance(str1, str2);
	const similarity = ((maxLen - distance) / maxLen) * 100;
	return similarity.toFixed(2); // Round to 2 decimal places
};

export const getUniqueByKey = (array, key) => {
	const uniqueValues = new Set();
	const uniqueItems = array.filter((item) => {
		const value = item[key];
		if (!uniqueValues.has(value)) {
			uniqueValues.add(value);
			return true;
		}
		return false;
	});
	return uniqueItems;
};

export const createEnhancedFilter = <T>(criteria: Criteria<T>) => {
	return (item: T): boolean => {
		return Object.keys(criteria).every((key) => {
			const k = key as keyof T;
			const criterion = criteria[k];

			if (typeof criterion === 'function') {
				return (criterion as (value: T[keyof T]) => boolean)(item[k]);
			}

			if (typeof criterion === 'object' && criterion !== null && 'contains' in criterion) {
				const containsValue = (criterion as { contains: string }).contains;
				const itemValue = item[k];

				if (typeof itemValue === 'string') {
					return itemValue.toLowerCase().includes(containsValue);
				}

				return false;
			}

			return item[k] === criterion;
		});
	};
};


export const createEventDate = (date: string, time: string) => {
	const [year, month, day] = date.split('-');
	const [timePart, modifier] = time.toLowerCase().split(' ');
	let [hours, minutes] = timePart.split(':').map(Number);

	if (modifier === 'pm' && hours < 12) {
		hours += 12;
	} else if (modifier === 'am' && hours === 12) {
		hours = 0;
	}

	const startDate = new Date(`${year}-${month}-${day}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`);
	const endDate = new Date(startDate);
	endDate.setMinutes(startDate.getMinutes() + 30);

	const timeZoneOffset = '+08:00';

	const formatDateToISO = (date: Date) => {
		return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:00${timeZoneOffset}`;
	};

	const startISO = formatDateToISO(startDate);
	const endISO = formatDateToISO(endDate);

	return { start: startISO, end: endISO };
};