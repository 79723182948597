<template>

</template>
<script setup>
const { gtag } = useGtag();
const store = useBookingStore();
const route = useRoute();
const { isGranted } = storeToRefs(store);
const consentEnable = () => {
    if (!isGranted.value) {
        gtag('consent', 'update', {
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'ad_storage': 'granted',
            'analytics_storage': 'granted',
            wait_for_update: 500,
        });
        store.setIsGranted();
    }
}
onMounted(() => {
    consentEnable();
});
watch(() => route.path, () => {
    consentEnable();
});
</script>