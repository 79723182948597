<template>
	<NuxtLoadingIndicator />
	<NuxtLayout>
		<NuxtPwaManifest />
		<NuxtPage class="mt-15" />
		<NeedHelp />
	</NuxtLayout>
	<ClientOnly>
		<GTagConsent />
	</ClientOnly>

</template>
<script setup>

import { loadCSSIfNotExists } from '~/utils/seo';
const store = useBookingStore();
const listingStore = useListingsStore();
const currencyStore = useCurrencyStore();
import { useDisplay } from 'vuetify';
const { currency } = storeToRefs(currencyStore);
const { cartItems } = storeToRefs(store);
const { mobile } = useDisplay();
const { countries, rates, reviews } = storeToRefs(listingStore);
const route = useRoute();
const conversionStore = useConversionStore();
const userCountryHeader = useRequestHeader('x-vercel-ip-country') || 'US';
const userLocaleHeader = useRequestHeader('accept-language')?.split(',')[0] || 'en-US';
const currencyData = countries.value.find((item) => item.countryCode === userCountryHeader);
if (currencyStore.getUserCurrency === null) {
	currencyStore.setInitialData(userLocaleHeader, currencyData);
}


watch(cartItems, async () => {
	const cartSize = computed(() => cartItems.value.length);
	if (cartSize.value > 0 && process.client && window) {
		await nextTick(() => {
			window.scrollTo(0, 0);
			setTimeout(() => shaker('cart'), 500);
		});


	}
}, { deep: true, immediate: true });

onMounted(() => {
	conversionStore.setQueries(route.query)
	nextTick(() => {
		store.setInitData(rates, countries, reviews);
		store.setState(currencyData);
		store.setIsMobile(mobile.value);
		setTimeout(() => {

			loadCSSIfNotExists(['https://cdn.jsdelivr.net/npm/@mdi/font@7.4.47/css/materialdesignicons.min.css', 'https://fonts.googleapis.com/css?family=Material+Icons', 'https://cdn.jsdelivr.net/npm/font-awesome@4.x/css/font-awesome.min.css']);
		}, 1500);
	});


});
watch(currency, () => {
	store.updateCartItemCurrency();
});


</script>
